import { autoinject, bindable } from 'aurelia-framework';
import { Visibility } from 'models/FileAttachment';
import { FileAttachmentService } from 'services/file-attachment-service';
import { FileService } from 'services/file-service';
import { DeleteDialogService } from './../services/delete-dialog-service';
import { AsyncErrorHandler } from 'lib/ui';
import { PdfPreviewController } from './pdf-preview-controller';

type File = {
  Id: number;
  FileName: string;
  FileUrl: string;
  Length: number;
  isPdf: boolean;
};

@autoinject()
export class FileList {
  @bindable public disabled: boolean;
  @bindable private parentId: number;
  @bindable private context?: string;
  @bindable private listOnly: boolean;
  @bindable private customerId: number;
  @bindable private parentServiceUrl: string;
  @bindable private visibility?: Visibility;

  private files: File[] = [];

  protected isPdf = false;

  constructor(
    private fileService: FileService,
    private fileAttachmentService: FileAttachmentService,
    private deleteDialogService: DeleteDialogService,
    private pdfPreviewController: PdfPreviewController
  ) { }

  async bind() {
    this.fileAttachmentService.setApiUrl(this.parentServiceUrl);
    if (this.context) {
      this.fileAttachmentService.setContext(this.context);
    }
    await this.getFiles();
  }

  @AsyncErrorHandler
  private async getFiles() {
    const files = (await this.fileAttachmentService.getFiles(this.parentId, this.visibility)) as File[];
    this.files = files.map((file) => {
      file['isPdf'] = file.FileName?.endsWith('.pdf');
      return file as File;
    });
  }

  @AsyncErrorHandler
  protected async previewFile(file: File) {
    const blob = (await this.fileService.download(file.Id, null, file.FileUrl, false)) as Blob;
    void this.pdfPreviewController.openPdfPreview({
      blob,
      fileName: file.FileName,
    });
  }

  @AsyncErrorHandler
  async downloadFile(file: { Id: string; FileUrl: string }) {
    await this.fileService.download(file.Id, null, file.FileUrl);
  }

  @AsyncErrorHandler
  async deleteFile(file: { Id: number }) {
    await this.deleteDialogService.confirmBeforeDelete(async () => {
      await this.fileAttachmentService.deleteFile(this.parentId, file.Id);
    });
    this.files = this.files.filter((x) => x.Id !== file.Id);
  }

  @AsyncErrorHandler
  async fileUploaded(event: { detail: { id: number } }) {
    await this.fileAttachmentService.addFile(this.parentId, event.detail.id, this.visibility);
    await this.getFiles();
  }
}
